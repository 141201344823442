<template>
  <v-container class="pa-0" mt-flex fluid>
  <v-parallax
    dark
    src="/imgs/gradientBG1.jpg"
    >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        class="text-center"
        cols="6"
        
      >
        <h1 class="text-h2 font-weight-black ts-2 mb-4 text-uppercase">
          <strong>Enrol <span class="customGreen--text">Today</span></strong>
        </h1>
        <h4 class="subheading text-uppercase">
          Please follow the link below to register online
        </h4>
        
      </v-col>
    </v-row>
  </v-parallax>
    <v-row class="pa-5 hidden-xs-only" align="center"
      justify="center">
        <v-col cols="6"  >
          <v-lazy 
          transition="view">
          <v-card
          class="rounded-lg float-right"
          elevation=5
          height="408px"
          max-width="75%"
          
          >
          <v-img
            src="/imgs/grpChildren.jpg"
            class="white--text align-end"
            gradient="to bottom, rgba(189,202,42,0), rgba(189,202,42,.1)"
            height="408px"
            >

          </v-img>
          </v-card>
          </v-lazy>
        </v-col>

        

        <v-col cols="6" class="pa-5">
          <h3 :class="appTexts.parties.titleColor" class="fs-2">Enrol Today for</h3><br/>
          <h1 class="onyx--text ozi-font fs-2">DM Dance</h1>
          <h3 :class="appTexts.parties.titleColor" class="fs-2">Classes</h3><br/>
          <h3 class="onyx--text">We offer the following classes:</h3><br/>
          <v-container class="pb-15">
            <v-list-item
              v-for="(item,i) in appTexts.danceClasses"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-container>
          <v-contanier class="pb-15 ">
          <v-btn
            class="ma-2"
            dark
            large
            rounded
            :color="appTexts.parties.titleColor"
            href="https://dmptanortheast.kidsoft.co.za/enroll.asp"
            target="_blank"
          >
          Enrol PTA East
          </v-btn>
          <v-btn
            class="ma-2"
            dark
            large
            rounded
            :color="appTexts.parties.titleColor"
            href="https://dmtotsptaeast.kidsoft.co.za/enroll.asp"
            target="_blank"
          >
          Enrol DM Tots PTA East
          </v-btn>
          <v-btn
            class="ma-2"
            dark
            large
            rounded
            :color="appTexts.parties.titleColor"
            href="https://dmptasoutheast.kidsoft.co.za/enroll.asp"
            target="_blank"
          >
          Enrol PTA South East
          </v-btn>
          </v-contanier>
          
        </v-col>
        
      </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
      }
    },
    computed: {
      
    },
    methods: {
      
    },
  }
</script>
<style>

.view-enter-active {
  animation-duration: 1.5s;
  animation-name: slideIn;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-delay: 1s ease-in;
}

@keyframes slideIn {
  from {
    opacity: 0;
    margin-left:-100%;
  }
  
  to {
    opacity: 1;
    margin-left:0%;
  }
}

.bg-grey {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-card.on-hover.theme--dark {
  transition: background-color 0.5s ease;
  background-color: rgba(#FFF, 0.8)
}
</style>